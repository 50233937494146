import React from 'react';
import img from "../../asset/images/bgImage.png";
import "../../style/signup.css";
import { IoMdContact } from 'react-icons/io';
import { HiOutlineMail } from 'react-icons/hi';
import { BiLockAlt } from 'react-icons/bi';
import { MdOutlineContactMail } from 'react-icons/md';
import Navbar from '../navbar/navbar';
import axios from 'axios';
import { Baseurl } from '../Baseurl';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { NavLink } from 'react-router-dom';

// Define a Yup schema for validation
const validationSchema = Yup.object({
  fname: Yup.string().required('Name is required'),
  lname: Yup.string().required('lname is required'),
  username: Yup.string().required('username is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  address: Yup.string().required('address is required'),
  password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters long'),
});


const Signup = () => {

  const token = localStorage.getItem("userToken")


  const initialValues = {
    fname: "",
    lname: "",
    username: "",
    email: "",
    address: "",
    password: ""
  };

  // const handleSubmit = (values) => {
  //   // Handle form submission
  //   console.log(values, "Handle form submission");
  // };

  // const [inpt, setInpt] = useState({
  //   fname: "",
  //   lname: "",
  //   username: "",
  //   email: "",
  //   password: "",
  //   address: ""

  // });




  // const inputData = (e) => {
  //   e.preventDefault();
  //   const datName = e.target.name;
  //   const datValue = e.target.value;
  //   setInpt({ ...inpt, [datName]: datValue })
  // }



  // console.log([inpt.fname, inpt.lname, inpt.email, inpt.password])


  const handleSubmit = (values, { resetForm }) => {

    const formdata = new FormData();

    formdata.append("username", values.username)
    formdata.append("fname", values.fname)
    formdata.append("lname", values.lname)
    formdata.append("email", values.email)
    formdata.append("password", values.password)
    formdata.append("address", values.address)

    // console.log(inpt)

    const configProduct = {
      method: "POST",
      url: `${Baseurl.baseurl}/api/register`,
      data: formdata,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`
      }
    }

    axios(configProduct)
      .then((res) => {
        resetForm();
        console.log(res, "product Response----->")
      }).catch((err) => {
        console.log(err)
      })
  }
  // const register = () => {
  //   userRegiter();
  // }



  return (
    <>
      <Navbar />
      <div style={{ width: "93vw"}} className='container-fluid  d-flex justify-content-center align-items-center'>
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-6 p-0'>
            <img src={img} width="100%" alt="img" />
          </div>
          <div className='col-lg-6 col-md-6 col-sm-6git bgColor'>
            <div className='maindiv'>
              <p style={{ color: "#a48528", margin: '0' }} className='text-center fs-1 fw-bold'>Sign Up</p>
              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {({ resetForm }) => (
                  <Form>
                    <div className='position-relative my-2'>
                      <Field className='input ps-3 text-white' type="text" id="fname" name="fname" placeholder='fname' autoComplete='off' />
                      <IoMdContact style={{ fontSize: "25px", position: "absolute", right: "10px", bottom: "10px", background: "transparent" }} />
                    </div>
                    <ErrorMessage name="fname" component="div" />

                    <div className='position-relative my-2'>
                      <Field className='input ps-3 text-white' type="text" id="lname" name="lname" placeholder='lname' autoComplete='off' />
                      <IoMdContact style={{ fontSize: "25px", position: "absolute", right: "10px", bottom: "10px", background: "transparent" }} />
                    </div>
                    <ErrorMessage name="lname" component="div" />

                    <div className='position-relative my-2'>
                      <Field className='input ps-3 text-white' type="text" id="lname" name="username" placeholder='username' autoComplete='off' />
                      <IoMdContact style={{ fontSize: "25px", position: "absolute", right: "10px", bottom: "10px", background: "transparent" }} />
                    </div>
                    <ErrorMessage name="username" component="div" />

                    <div className='position-relative my-2'>
                      <Field className='input ps-3 text-white' type="email" id="email" name="email" placeholder='Email' autoComplete='off' />
                      <HiOutlineMail style={{ fontSize: "25px", position: "absolute", right: "10px", bottom: "10px", background: "transparent" }} />
                    </div>
                    <ErrorMessage name="email" component="div" />

                    <div className='position-relative my-2'>
                      <Field className='input ps-3 text-white' type="text" id="address" name="address" placeholder='address' autoComplete='off' />
                      <MdOutlineContactMail style={{ fontSize: "25px", position: "absolute", right: "10px", bottom: "10px", background: "transparent" }} />
                    </div>
                    <ErrorMessage name="address" component="div" />

                    <div className='position-relative my-2'>
                      <Field className='input ps-3 text-white' type="password" id="password" name="password" placeholder='password' autoComplete='off' />
                      <BiLockAlt style={{ fontSize: "25px", position: "absolute", right: "10px", bottom: "10px", background: "transparent" }} />
                    </div>
                    <ErrorMessage name="password" component="div" />


                    <div className='my-2 text-center'>
                      <button type="submit" style={{ backgroundColor: "#a48528" }} className='btn border-0 w-100' >Register</button>
                    </div>
                  </Form>
                )}
              </Formik>

              <div className='mt-2 text-center'>
                <label className='fs-5 fw-bold'>Already have an account? <NavLink style={{ color: "#a48528" }} to='/login'>Login</NavLink></label>
              </div>
            </div>

          </div>
        </div>
      </div></>
  )
}

export default Signup