import React from 'react'
import axios from 'axios';
import { useState } from 'react';
import { Baseurl } from '../Baseurl';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
const navigate = useNavigate();

    const getData = async () => {
        const formdata = new FormData()
        formdata.append("token", inpt.Tokencode);
        formdata.append("password", inpt.password);
        formdata.append("password_confirmation", inpt.password_confirmation);

        try {
            const res = await axios.post(`${Baseurl.baseurl}/api/reset_password`, formdata)
            if (res.data.status === true) {
                alert(res.data.response)
                navigate("/")
                setInpt({
                    Tokencode: "",
                    password: "",
                    password_confirmation: ""
                });
            } else {
                alert(res.data.error)
            }

            console.log(res.data.response, "token")
        }
        catch (error) {
            console.log(error)
        }
    }

    const [inpt, setInpt] = useState({
        Tokencode: "",
        password: "",
        password_confirmation: ""
    });

    const inputData = (e) => {
        const datName = e.target.name;
        const datValue = e.target.value;
        setInpt({ ...inpt, [datName]: datValue })
    }


    console.log(inpt.password_confirmation, inpt.password, inpt.Tokencode, "inptEmail")

    return (
        <>
            <div className='container m-0' style={{height:'100vh'}}>
                <div className="row  flex-wrap justify-content-center align-items-center ">

                    <div className='col-lg-4 col-md-8'>
                        <form style={{ backgroundColor: "rgb(160, 125, 36)" }} className='p-4 mt-5'>

                            <div class="form-outline ">
                                <h3 class="form-label text-center" for="form2Example1">Reset password form</h3>
                            </div>
                            <div class="form-outline mb-2">
                                <input type="number" name='Tokencode' onChange={(e) => inputData(e)} value={inpt.Tokencode} id="form2Example1" className="form-control text-dark" />
                                <label class="form-label" for="form2Example1">Token Code</label>
                            </div>
                            <div class="form-outline mb-2">
                                <input type="password" name='password' onChange={(e) => inputData(e)} value={inpt.password} id="form2Example1" className="form-control text-dark" />
                                <label class="form-label" for="form2Example1">Password</label>
                            </div>

                            <div class="form-outline mb-4">
                                <input type="password" name='password_confirmation' onChange={(e) => inputData(e)} value={inpt.password_confirmation} class="form-control text-dark" />
                                <label class="form-label" for="form2Example2">Confirm-Password</label>
                            </div>


                            <button type="button" onClick={() => getData()} className="btn btn-outline-dark w-100   mb-4">Reset password</button>


                        </form></div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword;