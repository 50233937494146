import React, { useState } from 'react';
import Loginimage from "../../asset/images/bgImage.png";
import "../../style/sign.css";
import { TfiEmail } from 'react-icons/tfi';
import { BiLockAlt } from 'react-icons/bi';
import { NavLink, useNavigate } from 'react-router-dom';
import Navbar from '../navbar/navbar';
import { Baseurl } from '../Baseurl';
import axios from 'axios';
import { toast } from 'react-toastify';

const Login = () => {
  const token2 = localStorage.getItem("userToken")
  const navigate = useNavigate("");



  const [inpt, setInpt] = useState({
    email: "",
    password: ""
  });

  const inputData = (e) => {
    e.preventDefault()
    const datName = e.target.name;
    const datValue = e.target.value;
    setInpt({ ...inpt, [datName]: datValue })
  }


  const UserApi = () => {
    const formdata = new FormData();
    formdata.append("email", inpt.email)
    formdata.append("password", inpt.password)

    axios.post(`${Baseurl.baseurl}/api/login`, formdata).then((res) => {
      if (res.data.status === true) {
        localStorage.setItem("userToken", res.data.data.access_token);
        localStorage.setItem("userDetails", JSON.stringify(res.data.data));
        toast.success("Login Successfully")
        setInpt({
          email: "",
          password: ""
        })
        navigate("/home")

      } else {
        toast.error(res.data.error)
      }

    }).catch((err) => {
      console.log(err)
    })
  }
  const registerlogin = () => {
    console.log(inpt, "inpt.....")
    UserApi();
    setInpt({
      email: "",
      password: ""
    })
  }

  const [inpFunt, setInpFunt] = useState("");

  const inputData2 = (e) => {
    e.preventDefault();
    setInpFunt(e.target.value)
  }

  console.log(inpFunt, "inpFunt")

  const submitEmail = () => {
    const formdata = new FormData();
    formdata.append("email", inpFunt)

    const apiDat = {
      method: "post",
      url: `${Baseurl.baseurl}/api/forget`,
      data: formdata,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token2}`
      }
    }

    axios(apiDat).then((res) => {
      console.log(res, "forget Api")
      navigate("/resetpassword")
    }).catch((err) => {
      console.log(err)
    })

  }

  return (
    <>
      <Navbar />
      <section className="">
        <div className="container h-custom ">
          <div className="row d-flex justify-content-center h-100">

            <div className="col-md-9 col-lg-6 col-xl-6">
              <img src={Loginimage}
                className="img-fluid" alt="" />
            </div>
            <div className="col-md-9 col-lg-6 col-xl-6 h-100 formdiv1 ">
              <form autocomplete="off">
                <div className="register3">
                  <p className="small fw-bold ">Don't have an account ? <NavLink to="/signup"
                    className="register1">Register</NavLink></p>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <div className="divider d-flex align-items-center ">
                  <h1 className="text-center mx-3 mb-4">Sign In To Daraz App</h1>
                </div>

                <div className="divider d-flex align-items-center ">
                  <br />
                  <br />
                  <p className="text-center fw-bold mx-3 mb-3">Enter Your Details Below</p>
                </div>


                <div className=" mb-4 col-md-10 e-address1">
                  <div className="position-relative">
                    <input name='email' onChange={(e) => inputData(e)} value={inpt.email} type="email" id="form3Example3" className="form-control"
                      placeholder=" Enter a valid email address" autocomplete="false" />

                    <TfiEmail style={{ position: "absolute", top: "15px", left: "18px", zIndex: "123" }} />
                  </div>
                </div>


                <div className=" mb-3 col-md-10 e-address1">
                  <div className="position-relative">
                    <input name='password' onChange={(e) => inputData(e)} value={inpt.password} type="password" id="form3Example4" className="form-control"
                      placeholder=" Enter password" />
                    <BiLockAlt style={{ position: "absolute", top: "15px", left: "18px", zIndex: "123" }} />
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center col-md-10">

                  <div className="form-check mb-0">
                    <input className="form-check-input me-2 remember-bodr1" type="checkbox" value="" id="form2Example3" />
                    <label className="form-check-label remember-clr1" for="form2Example3">
                      Remember me
                    </label>
                  </div>
                  <div class="modal fade" id="exampleModal01" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title text-dark" id="exampleModalLabel">Email Details</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <form>
                            <div class="mb-3">
                              <label for="recipient-name" class="col-form-label text-dark">Email:</label>
                              <input type="email" name='email' onChange={(e) => inputData2(e)} class="form-control text-dark" id="recipient-name" placeholder='abc@gmail.com' />
                            </div>
                          </form>
                        </div>
                        <div class="modal-footer">
                          <button type="button" onClick={() => submitEmail()} className="btn border-0" data-bs-dismiss="modal">Send message</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ cursor: "pointer" }} className="border-0" data-bs-toggle="modal" data-bs-target="#exampleModal01" data-bs-whatever="@mdo">Forget password?</div>
                </div>

                <div className="text-center mt-4 pt-2 col-md-10">
                  <button onClick={registerlogin} type="button" className="btn w-100"
                    style={{ backgroundColor: "#a48528" }}>Login</button>

                </div>

              </form>
            </div>
          </div>
        </div>
        {/* <div
    className="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-primary">
   
    <div className="text-white mb-3 mb-md-0">
      Copyright © 2020. All rights reserved.
    </div>
 

    
    <div>
      <a href="#!" className="text-white me-4">
        <i className="fab fa-facebook-f"></i>
      </a>
      <a href="#!" className="text-white me-4">
        <i className="fab fa-twitter"></i>
      </a>
      <a href="#!" className="text-white me-4">
        <i className="fab fa-google"></i>
      </a>
      <a href="#!" className="text-white">
        <i className="fab fa-linkedin-in"></i>
      </a>
    </div> */}

        {/* </div> */}
      </section>
    </>
  )
}

export default Login;

